import {
    CallResult,
    getContract,
    IMotoswapRouterContract,
    JSONRpcProvider,
    MOTOSWAP_ROUTER_ABI,
} from 'opnet';
import { Address, BinaryReader } from '@btc-vision/bsi-binary';
import { PopulatedTransaction } from '../shared/types/transactions/PopuatedTransaction';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { getNetworkConfigKey } from '../utils/marketAndNetworkUtils';
import BigNumber from 'bignumber.js';

export interface TransferEvent {
    readonly from: Address;
    readonly to: Address;
    readonly value: bigint;
}

export class RouterService {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    public async addLiquidity(
        currentNetworkConfig: NetworkConfig,
        tokenA: Address,
        tokenB: Address,
        amountADesired: string,
        amountBDesired: string,
        amountAMin: string,
        amountBMin: string,
        to: Address,
        deadline: bigint,
        sender: Address,
    ): Promise<PopulatedTransaction> {
        const contract = getContract<IMotoswapRouterContract>(
            currentNetworkConfig.addresses.ROUTER,
            MOTOSWAP_ROUTER_ABI,
            this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
        );

        contract.setSender(sender);

        const contractResult = await contract.encodeCalldata('addLiquidity', [
            tokenA,
            tokenB,
            BigInt(amountADesired),
            BigInt(amountBDesired),
            BigInt(amountAMin),
            BigInt(amountBMin),
            to,
            deadline,
        ]);

        // const contractResult = await contract.addLiquidity(tokenA, tokenB, BigInt(amountADesired), BigInt(amountBDesired), BigInt(amountAMin), BigInt(amountBMin), to, deadline);

        // const callData = (contractResult as CallResult).calldata!;

        const tx: PopulatedTransaction = {
            toAddress: currentNetworkConfig.addresses.ROUTER,
            callData: contractResult,
        };

        return tx;
    }

    public async removeLiquidity(
        currentNetworkConfig: NetworkConfig,
        tokenA: Address,
        tokenB: Address,
        liquidity: bigint,
        amountAMin: bigint,
        amountBMin: bigint,
        to: Address,
        deadline: bigint,
        sender: Address,
    ): Promise<PopulatedTransaction> {
        const contract = getContract<IMotoswapRouterContract>(
            currentNetworkConfig.addresses.ROUTER,
            MOTOSWAP_ROUTER_ABI,
            this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
        );

        contract.setSender(sender);

        //abstract me
        const gas = 30_000n;
        try {
            const simulate = (await contract.removeLiquidity(
                tokenA,
                tokenB,
                liquidity,
                amountAMin,
                amountBMin,
                to,
                deadline,
            )) as CallResult;
            if (simulate.estimatedGas) {
                const tx: PopulatedTransaction = {
                    toAddress: currentNetworkConfig.addresses.ROUTER,
                    callData: simulate.calldata!,
                    estimatedGas: new BigNumber(simulate.estimatedGas.toString()),
                };

                return tx;
            }
        } catch {
            console.log('error simulating remove liquidity');
        }

        const contractResult = await contract.encodeCalldata('removeLiquidity', [
            [tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline],
        ]);

        const tx: PopulatedTransaction = {
            toAddress: currentNetworkConfig.addresses.ROUTER,
            callData: contractResult,
            estimatedGas: new BigNumber(gas.toString()),
        };

        return tx;
    }

    public async getQuote(
        currentNetworkConfig: NetworkConfig,
        amount: string,
        reserveA: string,
        reserveB: string,
    ): Promise<bigint> {
        try {
            const contract = getContract<IMotoswapRouterContract>(
                currentNetworkConfig.addresses.ROUTER,
                MOTOSWAP_ROUTER_ABI,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );

            const contractResult = await contract.quote(
                BigInt(amount),
                BigInt(reserveA),
                BigInt(reserveB),
            );

            const data = contractResult as CallResult;

            return data.decoded[0] as bigint;
        } catch (error) {
            console.error('Error in RouterService:getQuote');
            throw error;
        }
    }

    public async getAmountsOut(
        currentNetworkConfig: NetworkConfig,
        amountIn: string,
        tokenInAddress: string,
        tokenOutAddres: string,
    ): Promise<bigint> {
        try {
            const contract = getContract<IMotoswapRouterContract>(
                currentNetworkConfig.addresses.ROUTER,
                MOTOSWAP_ROUTER_ABI,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );

            const contractResult = await contract.getAmountsOut(BigInt(amountIn), [
                tokenInAddress,
                tokenOutAddres,
            ]);

            return (contractResult as CallResult<{ amountsOut?: bigint[] }>).properties
                .amountsOut![1];
        } catch (err) {
            throw err;
        }
    }

    public async swap(
        currentNetworkConfig: NetworkConfig,
        tokenInAmount: string,
        tokenOutAmount: string,
        path: [string, string],
        to: string,
        deadline: bigint,
    ): Promise<PopulatedTransaction> {
        const contract = getContract<IMotoswapRouterContract>(
            currentNetworkConfig.addresses.ROUTER,
            MOTOSWAP_ROUTER_ABI,
            this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            to,
        );

        const callData = await contract.encodeCalldata(
            'swapExactTokensForTokensSupportingFeeOnTransferTokens',
            [BigInt(tokenInAmount), BigInt(tokenOutAmount), path, to, deadline],
        );

        const tx: PopulatedTransaction = {
            toAddress: currentNetworkConfig.addresses.ROUTER,
            callData: callData,
        };

        return tx;
    }

    public decodeTransferEvent(data: Buffer | Uint8Array): TransferEvent {
        const bytes = Uint8Array.from(data);

        const reader = new BinaryReader(bytes);
        const from = reader.readAddress();
        const to = reader.readAddress();
        const value = reader.readU256();

        return { from, to, value };
    }
}
