import BigNumber from 'bignumber.js';
import { useState, useCallback, useEffect } from 'react';
import { useSharedDependencies } from '../providers/SharedDependenciesProvider';
import { useRootStore } from '../store/root';
import { Token } from '../shared/types/Token';

export interface UseReservesDataParams {
    tokenIn: Token | undefined;
    tokenOut: Token | undefined;
}

export interface UseReservesDataResult {
    reserves: { reserve0: BigNumber; reserve1: BigNumber } | null;
    poolAddress: string | undefined;
    loading: boolean;
    error: string | undefined;
    refetch: () => void;
}

export const useReservesData = ({
    tokenIn,
    tokenOut,
}: UseReservesDataParams): UseReservesDataResult => {
    const currentNetworkConfig = useRootStore((root) => root.currentNetworkConfig);
    const [reserves, setReserves] = useState<{ reserve0: BigNumber; reserve1: BigNumber } | null>(
        null,
    );
    const [poolAddress, setPoolAddress] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const { factoryService, poolService } = useSharedDependencies();

    const getReserveData = useCallback(async () => {
        if (!tokenIn || !tokenOut) {
            setReserves(null);
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(undefined);

        try {
            const poolAddress = await factoryService.GetDecodedPoolAddress(
                currentNetworkConfig,
                tokenIn.contractAddress,
                tokenOut.contractAddress,
            );

            if (!poolAddress) {
                setPoolAddress(undefined);
            }
            setPoolAddress(poolAddress);

            const reserves = await poolService.GetReserveDataHumanized(
                currentNetworkConfig,
                poolAddress,
                tokenIn.contractAddress,
                tokenOut.contractAddress,
            );

            if (!reserves) {
                throw new Error('Reserves data not found');
            }

            setReserves({
                reserve0: new BigNumber(reserves.reserve0.toString()),
                reserve1: new BigNumber(reserves.reserve1.toString()),
            });
            setLoading(false);
        } catch (err) {
            setError('Error fetching reserves data');
            setLoading(false);
        }
    }, [tokenIn, tokenOut]);

    useEffect(() => {
        getReserveData();
    }, [getReserveData]);

    return { reserves, poolAddress, loading, error, refetch: getReserveData };
};
