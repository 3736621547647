import {
    BaseContractProperty,
    CallResult,
    getContract,
    IMotoswapFactoryContract,
    JSONRpcProvider,
    MotoSwapFactoryAbi,
} from 'opnet';
import { PopulatedTransaction } from '../shared/types/transactions/PopuatedTransaction';
import { AddressGenerator } from '@btc-vision/transaction';
import { Buffer } from 'buffer';
import { BinaryWriter } from '@btc-vision/bsi-binary';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { getNetworkConfigKey } from '../utils/marketAndNetworkUtils';

export class FactoryService {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    async GetDecodedPoolAddress(
        currentNetworkConfig: NetworkConfig,
        tokenA: string,
        tokenB: string,
    ): Promise<string> {
        try {
            const contract = getContract<IMotoswapFactoryContract>(
                currentNetworkConfig.addresses.FACTORY,
                MotoSwapFactoryAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );
            const contractResult: BaseContractProperty = await contract.getPool(tokenA, tokenB);
            const poolBigInt = (contractResult as CallResult<{ pool?: bigint }>).properties.pool!;
            const writer = new BinaryWriter();
            writer.writeU256(poolBigInt);
            const vAddress = Buffer.from(writer.getBuffer());
            const address = AddressGenerator.generatePKSH(vAddress, currentNetworkConfig.network);

            return address;
        } catch (error) {
            console.error('error in FactoryService:GetPool', error);
            throw error;
        }
    }

    async GetPool(
        currentNetworkConfig: NetworkConfig,
        tokenA: string,
        tokenB: string,
    ): Promise<CallResult> {
        try {
            const contract = getContract<IMotoswapFactoryContract>(
                currentNetworkConfig.addresses.FACTORY,
                MotoSwapFactoryAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );
            const contractResult: BaseContractProperty = await contract.getPool(tokenA, tokenB);
            return contractResult as CallResult;
        } catch (error) {
            console.error('error in FactoryService:GetPool', error);
            throw error;
        }
    }

    async CreatePool(
        currentNetworkConfig: NetworkConfig,
        tokenA: string,
        tokenB: string,
    ): Promise<PopulatedTransaction> {
        try {
            const contract = getContract<IMotoswapFactoryContract>(
                currentNetworkConfig.addresses.FACTORY,
                MotoSwapFactoryAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );

            const contractResult: BaseContractProperty = await contract.createPool(tokenA, tokenB);

            const tx: PopulatedTransaction = {
                toAddress: currentNetworkConfig.addresses.FACTORY,
                callData: (contractResult as CallResult).calldata!,
            };
            return tx;
        } catch (error) {
            console.error('error in FactoryService:GetPool', error);
            throw error;
        }
    }
}
