export const checkRequiresApproval = ({
    approvedAmount,
    amount,
}: {
    approvedAmount: bigint;
    amount: bigint;
}) => {
    if (approvedAmount != undefined && approvedAmount !== 0n && approvedAmount >= amount) {
        return false;
    } else {
        return true;
    }
};
