import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Paper } from '@mui/material';
import { useSharedDependencies } from '../../providers/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';
import { fromBigInt, toBigInt } from '../../utils/tokenUtils';
import AssetInput from '../../components/inputs/AssetInput';
import { Token } from '../../shared/types/Token';
import { CogIcon } from '@heroicons/react/16/solid';
import SlippageSettingsPopover from '../../components/modals/TransactionSettingsModule';
import { useSnackbarContext } from '../../providers/SnackProvider';
import { useModalContext } from '../../hooks/useModal';

const AddLiquidityPanel: React.FC = () => {
    const { openAddLiquidity } = useModalContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { routerService, factoryService, poolService } = useSharedDependencies();
    const [currentNetworkConfig] = useRootStore((store) => [store.currentNetworkConfig]);
    const { showSnackbar } = useSnackbarContext();

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const [tokenA, setTokenA] = useState<Token | undefined>();
    const [tokenAAmount, setTokenAAmount] = React.useState<string>('');
    const handleTokenA = (token: Token) => {
        setTokenA(token);
    };

    const [tokenB, setTokenB] = useState<Token | undefined>();
    const [tokenBAmount, setTokenBAmount] = React.useState<string>('');
    const handleTokenB = (token: Token) => {
        setTokenB(token);
    };

    useEffect(() => {
        const calculateOutputAmount = async () => {
            try {
                if (tokenAAmount == '0' || !tokenAAmount || isNaN(parseFloat(tokenAAmount))) {
                    setTokenBAmount('');
                } else {
                    if (
                        tokenA &&
                        tokenB &&
                        tokenAAmount &&
                        !isNaN(parseFloat(tokenAAmount)) &&
                        parseFloat(tokenAAmount) > 0
                    ) {
                        const tokenAAmtBig = toBigInt(tokenAAmount, tokenA.decimals);
                        const pool = await factoryService.GetDecodedPoolAddress(
                            currentNetworkConfig,
                            tokenA.contractAddress,
                            tokenB.contractAddress,
                        );
                        const res = await poolService.GetReserveDataHumanized(
                            currentNetworkConfig,
                            pool,
                            tokenA.contractAddress,
                            tokenB.contractAddress,
                        );
                        const quote = await routerService.getQuote(
                            currentNetworkConfig,
                            tokenAAmtBig.toString(),
                            res.reserve1.toString(),
                            res.reserve0.toString(),
                        );
                        const amount = fromBigInt(quote, tokenB.decimals);
                        setTokenBAmount(amount.toString());
                    }
                }
            } catch (error) {
                showSnackbar('error fetching reserve data');
            }
        };
        if (tokenA && tokenB && tokenAAmount !== null && !isNaN(parseFloat(tokenAAmount))) {
            calculateOutputAmount();
        }
    }, [tokenA, tokenB, tokenAAmount]);

    const onClick = () => {
        if (tokenA && tokenAAmount && tokenB && tokenBAmount)
            openAddLiquidity(tokenA, tokenAAmount, tokenB, tokenBAmount)
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: { xs: 2, sm: 4 },
                boxSizing: 'border-box',
                position: 'relative',
            }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: 4, sm: 8 },
                    right: { xs: 4, sm: 8 },
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <IconButton
                    sx={{
                        height: '40px',
                        width: '40px',
                        color: '#ffffff',
                    }}
                    onClick={handleSettingsClick}>
                    <CogIcon />
                </IconButton>
            </Box>
            <Box
                sx={{ pt: { xs: 3, sm: 2 } }}
                display="flex"
                flexDirection="column">
                <Box
                    mb={4}
                    width="100%">
                    <AssetInput
                        title=""
                        tokenAmount={tokenAAmount}
                        setTokenAmount={setTokenAAmount}
                        setUserSelectedToken={handleTokenA}
                    />
                </Box>
                <Box
                    mb={4}
                    width="100%">
                    <AssetInput
                        title=""
                        tokenAmount={tokenBAmount}
                        setTokenAmount={setTokenBAmount}
                        setUserSelectedToken={handleTokenB}
                    />
                </Box>

                <Button
                    sx={{ mt: 3 }}
                    variant="violet"
                    onClick={() => onClick()}
                    fullWidth>
                    Add Liquidity
                </Button>

                <SlippageSettingsPopover
                    anchorEl={anchorEl}
                    open={openPopover}
                    onClose={handleClose}
                />
            </Box>
        </Paper>
    );
};

export default AddLiquidityPanel;
