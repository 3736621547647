import BigNumber from 'bignumber.js';
import { useState, useCallback, useEffect } from 'react';
import { useSharedDependencies } from '../providers/SharedDependenciesProvider';
import { useSnackbarContext } from '../providers/SnackProvider';
import { Token } from '../shared/types/Token';
import { toBigInt, fromBigInt } from '../utils/tokenUtils';
import { useReservesData } from './useReservesData';
import { useRootStore } from '../store/root';

interface UsePriceImpactParams {
    amountIn: string;
    tokenIn: Token | undefined;
    tokenOut: Token | undefined;
    reserves: { reserve0: BigNumber; reserve1: BigNumber } | null;
}

interface UsePriceImpactResult {
    expectedOutput: string;
    priceImpact: number | null;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export const usePriceImpact = ({
    amountIn,
    tokenIn,
    tokenOut,
}: UsePriceImpactParams): UsePriceImpactResult => {
    const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);
    const { showSnackbar } = useSnackbarContext();
    const [expectedOutput, setExpectedOutput] = useState<string>('0');
    const [priceImpact, setPriceImpact] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const { routerService } = useSharedDependencies();

    const {
        reserves,
        loading: reservesLoading,
        error: reservesError,
        refetch: refetchReserves,
    } = useReservesData({ tokenIn, tokenOut });

    const getData = useCallback(async () => {
        if (
            !tokenIn ||
            !tokenOut ||
            !amountIn ||
            isNaN(parseFloat(amountIn)) ||
            parseFloat(amountIn) === 0
        ) {
            setExpectedOutput('0');
            setPriceImpact(null);
            setLoading(false);
            return;
        }

        if (reservesLoading || reservesError || !reserves) {
            setLoading(false);
            setError(reservesError || 'Error fetching reserves data');
            showSnackbar(reservesError || 'wowah');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const amountOut = await routerService.getAmountsOut(
                currentNetworkConfig,
                toBigInt(amountIn, tokenIn.decimals).toString(),
                tokenIn.contractAddress,
                tokenOut.contractAddress,
            );
            const expectedOutputValue = fromBigInt(amountOut, tokenOut.decimals).toString();
            setExpectedOutput(expectedOutputValue);

            const reserve0 = reserves.reserve0;
            const reserve1 = reserves.reserve1;

            const quote = await routerService.getQuote(
                currentNetworkConfig,
                toBigInt(amountIn, tokenIn.decimals).toString(),
                reserve1.toString(),
                reserve0.toString(),
            );
            const quoteBN = new BigNumber(quote.toString());
            const amountOutBn = new BigNumber(amountOut.toString());
            const ratio = amountOutBn.dividedBy(quoteBN);
            const priceImpactValue =
                new BigNumber(1).minus(ratio).multipliedBy(100).toNumber() * -1;
            setPriceImpact(priceImpactValue);

            setLoading(false);
        } catch (err) {
            setError('Error fetching swap amount or calculating price impact');
            setLoading(false);
        }
    }, [amountIn, tokenIn, tokenOut, routerService, reserves, reservesLoading, reservesError]);

    useEffect(() => {
        getData();
    }, [getData, refetchReserves]);

    return { expectedOutput, priceImpact, loading, error, refetch: getData };
};
