import { WalletType } from '../shared/types/WalletTypes';
import { StateCreator } from 'zustand';

import { RootStore } from './root';
import { UTXO } from 'opnet';
import { BitcoinBalance } from '../shared/types/wallet/BitcoinBalance';
import { Signer } from 'bitcoinjs-lib';

export enum ApprovalMethod {
    APPROVE = 'Transaction',
    PERMIT = 'Signed message',
}

export interface WalletSlice {
    account: string;
    accountLoading: boolean;
    walletType: WalletType | undefined;
    setAccount: (account: string | undefined) => void;
    setAccountLoading: (loading: boolean) => void;
    connectedNetwork: string | undefined;
    setConnectedNetwork: (connectedNetwork: string | undefined) => void;
    setWalletType: (walletType: WalletType | undefined) => void;
    isWalletModalOpen: boolean;
    signer: Signer | undefined;
    setSigner: (signer: Signer) => void;
    setWalletModalOpen: (open: boolean) => void;
    walletApprovalMethodPreference: ApprovalMethod;
    setWalletApprovalMethodPreference: (method: ApprovalMethod) => void;
    refreshWalletApprovalMethod: () => void;
    walletUtxos: UTXO[];
    setWalletUtxos: (utxo: UTXO[]) => void;
    bitcoinBalance: BitcoinBalance | undefined;
    setBitcoinBalance: (balance: BitcoinBalance | undefined) => void;
}

const getWalletPreferences = () => {
    const walletPreference = localStorage.getItem('walletApprovalPreferences');
    if (walletPreference) {
        return JSON.parse(walletPreference);
    } else {
        return {};
    }
};

const getLocalUxtos = () => {
    const storedUtxoData = localStorage.getItem('utxoData');
    if (storedUtxoData) {
        return JSON.parse(storedUtxoData);
    } else {
        return [];
    }
};

export const createWalletSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    WalletSlice
> = (set, get) => {
    const initialUtxoData = getLocalUxtos();
    const intialaBitcoinBalance = { confirmed: 0, unconfirmed: 0, total: 0 };

    return {
        account: '',
        accountLoading: false,
        walletType: undefined,
        bitcoinBalance: intialaBitcoinBalance,
        setBitcoinBalance(balance) {
            set({ bitcoinBalance: balance });
        },
        signer: undefined,
        setSigner(signer: Signer) {
            set({ signer: signer });
        },
        setWalletType(walletType) {
            set({ walletType });
        },
        setAccount(account) {
            set({ account: account || '', isWalletModalOpen: false });
        },
        connectedNetwork: undefined,
        setConnectedNetwork(connectedNetwork) {
            set({ connectedNetwork: connectedNetwork });
        },
        setAccountLoading(loading) {
            set({ accountLoading: loading });
        },
        isWalletModalOpen: false,
        setWalletModalOpen(open) {
            set({ isWalletModalOpen: open });
        },
        walletApprovalMethodPreference: ApprovalMethod.PERMIT,
        setWalletApprovalMethodPreference: (method: ApprovalMethod) => {
            const account = get().account;
            if (account !== '') {
                const walletPreferencesObject = getWalletPreferences();
                walletPreferencesObject[account.toLowerCase()] = method;
                localStorage.setItem(
                    'walletApprovalPreferences',
                    JSON.stringify(walletPreferencesObject),
                );
                set(() => ({
                    walletApprovalMethodPreference: method,
                }));
            }
        },
        refreshWalletApprovalMethod: () => {
            const account = get().account;
            if (account !== '') {
                const walletPreferencesObject = getWalletPreferences();
                const accountPreference = walletPreferencesObject[account.toLowerCase()];
                set(() => ({
                    walletApprovalMethodPreference: accountPreference
                        ? accountPreference
                        : ApprovalMethod.PERMIT,
                }));
            }
        },
        walletUtxos: initialUtxoData,
        setWalletUtxos: (utxos: UTXO[]) => {
            set({ walletUtxos: utxos });
        },
    };
};
