// src/components/Swap.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { Token } from '../../shared/types/Token';
import AssetInput from '../../components/inputs/AssetInput';
import { usePriceImpact } from '../../hooks/usePriceImpact';
import { useReservesData } from '../../hooks/useReservesData';
import { useModalContext } from '../../hooks/useModal';

const SwapPanel: React.FC = () => {
    const { openSwap } = useModalContext();
    const [tokenIn, setTokenIn] = useState<Token | undefined>(undefined);
    const [tokenInAmount, setTokenInAmount] = useState("");
    const [tokenOut, setTokenOut] = useState<Token | undefined>(undefined);
    const [tokenOutAmount, setTokenOutAmount] = useState("");

    const handleSelectSellToken = (token: Token) => {
        if (tokenOut)
            if (token.contractAddress === tokenOut?.contractAddress) {
                handleSwitch();
            }
        setTokenIn(token);
    };

    const handleSelectBuyToken = (token: Token) => {
        if (tokenIn)
            if (token.contractAddress === tokenIn.contractAddress) {
                handleSwitch();
            }
        setTokenOut(token);
    };

    const handleSwitch = () => {
        const newTokenIn = tokenOut;
        const newTokenOut = tokenIn;
        setTokenIn(newTokenIn);
        setTokenOut(newTokenOut);
        setTokenInAmount("");
        setTokenOutAmount("");
    };

    const { reserves } = useReservesData({ tokenIn, tokenOut });
    const { expectedOutput, priceImpact } = usePriceImpact({
        amountIn: tokenInAmount,
        tokenIn,
        tokenOut,
        reserves,
    });

    useEffect(() => {
        if (!tokenInAmount) {
            setTokenOutAmount('');
        } else {
            setTokenOutAmount(expectedOutput);
        }
    }, [expectedOutput, tokenInAmount]);

    const onSwapClick = () => {
        if (tokenIn && tokenInAmount && tokenOut && tokenOutAmount && priceImpact)
            openSwap(tokenIn, tokenInAmount, tokenOut, tokenOutAmount, priceImpact);
    };

    return (
        <Box
            sx={{ pt: { xs: 4, sm: 2 } }}
            display="flex"
            flexDirection="column">
            <Box width="100%">
                <AssetInput
                    tokenContractAddress={tokenIn?.contractAddress}
                    title="Sell"
                    tokenAmount={tokenInAmount}
                    setTokenAmount={setTokenInAmount}
                    setUserSelectedToken={handleSelectSellToken}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ArrowDownward
                    sx={{ color: 'gray', cursor: 'pointer' }}
                    onClick={handleSwitch}
                />
            </Box>
            <Box width="100%">
                <AssetInput
                    tokenContractAddress={tokenOut?.contractAddress}
                    title="Buy"
                    tokenAmount={tokenOutAmount}
                    setTokenAmount={setTokenOutAmount}
                    setUserSelectedToken={handleSelectBuyToken}
                    priceImpact={priceImpact}
                />
            </Box>
            {/* {!reserves && (
                <Typography color="error" sx={{ mt: 2 }}>
                    Liquidity is not available for the selected token pair.
                </Typography>
            )} */}
            <Button
                sx={{ mt: 3 }}
                disabled={!tokenIn || !tokenOut || !tokenInAmount || !tokenOutAmount}
                variant="violet"
                onClick={() => onSwapClick()}
                fullWidth>
                Swap {tokenIn?.symbol} for {tokenOut?.symbol}
            </Button>
        </Box>
    );
};

export default SwapPanel;
