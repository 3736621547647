import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import { PageWithLayout } from './shared/types/index.js';
import Pool from './pages/pools/Pool';
import NotFound from './pages/NotFound';
import Swap from './pages/swap/Swap';
import Create from './pages/pools/create/Create';
import Find from './pages/pools/find/Find';

const Routes: React.FC = () => {
    const renderWithLayout = (Component: PageWithLayout) => {
        const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);
        return getLayout(<Component />);
    };

    return (
        <Switch>
            <Route
                path="/"
                element={renderWithLayout(Swap)}
            />
            <Route
                path="/pools"
                element={renderWithLayout(Pool)}
            />
            <Route
                path="/pools/find"
                element={renderWithLayout(Find)}
            />
            <Route
                path="/pools/create"
                element={renderWithLayout(Create)}
            />
            <Route
                path="*"
                element={<NotFound />}
            />
        </Switch>
    );
};

export default Routes;
