// src/theme.ts
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

const theme = createTheme();

const {
    typography: { pxToRem },
} = theme;

const FONT = 'Space Mono';
const TITLE_FONT = 'VCR OSD Mono';

declare module '@mui/material/styles/createPalette' {
    interface PaletteColor extends ColorPartial { }

    interface TypeText {
        muted: string;
        contrast: string;
        accent: string;
    }

    interface TypeBackground {
        default: string;
        paper: string;
        surface: string;
        accentViolet: string;
        accentPink: string;
    }

    interface Palette {
        gradients: {
            motoGradient: string;
            motoSecondaryGradient: string;
        };
        other: {
            standardInputLine: string;
        };
    }

    interface PaletteOptions {
        outlines: {
            formFields: string;
        };
        gradients: {
            motoGradient: string;
            motoSecondaryGradient: string;
        };
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants extends TypographyCustomVariants { }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions extends TypographyCustomVariants { }

    interface BreakpointOverrides {
        xsm: true;
        xxl: true;
    }
}

interface TypographyCustomVariants {
    display1: React.CSSProperties;
    subheader1: React.CSSProperties;
    subheader2: React.CSSProperties;
    description: React.CSSProperties;
    buttonL: React.CSSProperties;
    buttonM: React.CSSProperties;
    buttonS: React.CSSProperties;
    helperText: React.CSSProperties;
    tooltip: React.CSSProperties;
    main21: React.CSSProperties;
    secondary21: React.CSSProperties;
    main16: React.CSSProperties;
    secondary16: React.CSSProperties;
    main14: React.CSSProperties;
    secondary14: React.CSSProperties;
    main12: React.CSSProperties;
    secondary12: React.CSSProperties;
    cardtitle: React.CSSProperties;
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        display1: true;
        subheader1: true;
        subheader2: true;
        description: true;
        buttonL: true;
        buttonM: true;
        buttonS: true;
        helperText: true;
        tooltip: true;
        main21: true;
        secondary21: true;
        main16: true;
        secondary16: true;
        main14: true;
        secondary14: true;
        main12: true;
        secondary12: true;
        h5: false;
        h6: false;
        subtitle1: false;
        subtitle2: false;
        body1: false;
        body2: false;
        button: false;
        overline: false;
        cardtitle: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        violet: true;
        pink: true;
        surface: true;
        gradient: false;
        disabled: true;
        outlinedWhite: true;
        outlined: true;
        outlinedYellow: true;
    }
}

export const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => {
    const getColor = (lightColor: string, darkColor: string) =>
        mode === 'light' ? lightColor : darkColor;

    return {
        palette: {
            mode,
            primary: {
                main: getColor('#1976d2', '#90caf9'),
            },
            secondary: {
                main: getColor('#44108C', '#44108C'),
            },
            info: {
                main: getColor('#E7E7E9', '#E7E7E9'),
            },
            outlines: {
                formFields: getColor('#E7E7E9', '#E7E7E9'),
            },
            background: {
                default: getColor('#f5f5f5', '#121212'),
                paper: getColor('#E7E7E9', '#E7E7E9'),
                accentViolet: getColor('#44108C', '#44108C'),
                accentPink: getColor('#6a1b9a', '#6a1b9a'),
            },
            text: {
                primary: getColor('#E7E7E9', '#E7E7E9'),
            },
            gradients: {
                motoGradient: 'linear-gradient(to bottom, #0F0C43, #560393)',
                motoSecondaryGradient: 'linear-gradient(90deg, #120B26, #100A22)',
            },
        },
        breakpoints: {
            keys: ['xs', 'xsm', 'sm', 'md', 'lg', 'xl', 'xxl'],
            values: {
                xs: 0,
                xsm: 640,
                sm: 760,
                md: 960,
                lg: 1280,
                xl: 1575,
                xxl: 1800,
            },
        },
        typography: {
            fontFamily: FONT,
            h5: undefined,
            h6: undefined,
            subtitle1: undefined,
            subtitle2: undefined,
            body1: undefined,
            body2: undefined,
            button: undefined,
            overline: undefined,
            display1: {
                fontFamily: TITLE_FONT,
                color: '#E7E7E9',
                fontWeight: 400,
                lineHeight: '48px',
                fontSize: pxToRem(42),
                textTransform: 'uppercase',
            },
            h1: {
                fontFamily: FONT,
                fontWeight: 900,
                letterSpacing: pxToRem(0.25),
                fontSize: pxToRem(22),
                fontStyle: 'italic',
                textTransform: 'uppercase',
            },
            h2: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: 'unset',
                lineHeight: '133.4%',
                fontSize: pxToRem(22),
                textTransform: 'uppercase',
                color: '#E7E7E9',
            },
            h3: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: pxToRem(0.15),
                lineHeight: '160%',
                fontSize: pxToRem(18),
                fontStyle: 'italic',
                textTransform: 'uppercase',
            },
            h4: {
                fontFamily: FONT,
                fontWeight: 400,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(24),
                fontSize: pxToRem(16),
            },
            cardtitle: {
                //italic looking
                fontFamily: 'Inter',
                fontWeight: 950,
                letterSpacing: '-0.02em',
                fontSize: '26px',
                lineHeight: '39px',
                fontStyle: 'italic',
                textTransform: 'uppercase',
                textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            },
            subheader1: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(20),
                fontSize: pxToRem(14),
            },
            subheader2: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.1),
                lineHeight: pxToRem(16),
                fontSize: pxToRem(12),
            },
            description: {
                fontFamily: FONT,
                fontWeight: 400,
                letterSpacing: pxToRem(0.15),
                lineHeight: '143%',
                fontSize: pxToRem(14),
            },
            caption: {
                fontFamily: FONT,
                fontWeight: 400,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(16),
                fontSize: pxToRem(12),
            },
            buttonL: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.46),
                lineHeight: pxToRem(24),
                fontSize: pxToRem(16),
            },
            buttonM: {
                fontFamily: FONT,
                fontWeight: 500,
                lineHeight: pxToRem(24),
                fontSize: pxToRem(14),
            },
            buttonS: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: pxToRem(0.46),
                lineHeight: pxToRem(20),
                textTransform: 'uppercase',
                fontSize: pxToRem(10),
            },
            helperText: {
                fontFamily: FONT,
                fontWeight: 400,
                letterSpacing: pxToRem(0.4),
                lineHeight: pxToRem(12),
                fontSize: pxToRem(10),
            },
            tooltip: {
                fontFamily: FONT,
                fontWeight: 400,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(16),
                fontSize: pxToRem(12),
            },
            main21: {
                fontFamily: FONT,
                fontWeight: 800,
                lineHeight: '133.4%',
                fontSize: pxToRem(21),
            },
            secondary21: {
                fontFamily: FONT,
                fontWeight: 500,
                lineHeight: '133.4%',
                fontSize: pxToRem(21),
            },
            main16: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(24),
                fontSize: pxToRem(16),
            },
            secondary16: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(24),
                fontSize: pxToRem(16),
            },
            main14: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(20),
                fontSize: pxToRem(14),
            },
            secondary14: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.15),
                lineHeight: pxToRem(20),
                fontSize: pxToRem(14),
            },
            main12: {
                fontFamily: FONT,
                fontWeight: 600,
                letterSpacing: pxToRem(0.1),
                lineHeight: pxToRem(16),
                fontSize: pxToRem(12),
            },
            secondary12: {
                fontFamily: FONT,
                fontWeight: 500,
                letterSpacing: pxToRem(0.1),
                lineHeight: pxToRem(16),
                fontSize: pxToRem(12),
            },
        },
    };
};

export function getThemedComponents(theme: Theme) {
    return {
        components: {
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        transform: 'unset',
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        background: 'linear-gradient(to bottom, #0F0C43, #560393)', // Gradient background
                        backgroundSize: 'cover', // Ensure the background covers the viewport
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed', // Ensures the background stays fixed while scrolling
                        fontFamily: '"VCR OSD Mono", monospace',
                        fontWeight: 400,
                        fontSize: '14px',
                        minWidth: '375px',
                        position: 'relative',
                        margin: 0,
                        padding: 0,
                        overflow: 'hidden',
                        '&::before': {
                            content: '""',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: 'url(/images/util/bg_noise.png)',
                            backgroundRepeat: 'repeat',
                            mixBlendMode: 'soft-light',
                            opacity: 0.15,
                            pointerEvents: 'none',
                            zIndex: -1,
                            '@media (max-width: 600px)': {
                                // Adjust for mobile devices
                                opacity: 0.15,
                            },
                        },
                    },
                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        paddingBottom: '39px',
                        [theme.breakpoints.up('xs')]: {
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        },
                        [theme.breakpoints.up('xsm')]: {
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        },
                        [theme.breakpoints.up('sm')]: {
                            paddingLeft: '48px',
                            paddingRight: '48px',
                        },
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: '96px',
                            paddingRight: '96px',
                        },
                        [theme.breakpoints.up('lg')]: {
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        },
                        [theme.breakpoints.up('xl')]: {
                            maxWidth: 'unset',
                            paddingLeft: '96px',
                            paddingRight: '96px',
                        },
                        [theme.breakpoints.up('xxl')]: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: '1440px',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        background: theme.palette.gradients.motoSecondaryGradient,
                        opacity: 100,
                        borderRadius: '10px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
                    },
                },
                variants: [
                    {
                        props: { variant: 'outlined' },
                        style: {
                            border: `1px solid ${theme.palette.divider}`,
                            boxShadow:
                                '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
                            background: theme.palette.background.paper,
                        },
                    },
                    {
                        props: { variant: 'elevation' },
                        style: {
                            boxShadow:
                                '0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)',
                            ...(theme.palette.mode === 'dark' ? { backgroundImage: 'none' } : {}),
                        },
                    },
                ],
            },
            MuiTypography: {
                defaultProps: {
                    variant: 'description',
                    variantMapping: {
                        display1: 'h1',
                        h1: 'h1',
                        h2: 'h2',
                        h3: 'h3',
                        h4: 'h4',
                        cardtitle: 'cardtitle',
                        subheader1: 'p',
                        subheader2: 'p',
                        caption: 'p',
                        description: 'p',
                        buttonL: 'p',
                        buttonM: 'p',
                        buttonS: 'p',
                        main12: 'p',
                        main14: 'p',
                        main16: 'p',
                        main21: 'p',
                        secondary12: 'p',
                        secondary14: 'p',
                        secondary16: 'p',
                        secondary21: 'p',
                        helperText: 'span',
                        tooltip: 'span',
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                    },
                    sizeLarge: {
                        ...theme.typography.buttonL,
                        padding: '10px 24px',
                    },
                    sizeMedium: {
                        ...theme.typography.buttonM,
                        padding: '6px 12px',
                    },
                    sizeSmall: {
                        ...theme.typography.buttonS,
                        padding: '0 6px',
                    },
                },
                variants: [
                    {
                        props: { variant: 'violet' },
                        style: {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.accentViolet,
                            '&:hover, &.Mui-focusVisible': {
                                borderColor: theme.palette.primary.light,
                                color: theme.palette.text.primary,
                                backgroundColor:theme.palette.background.accentPink,
                            },
                        },
                    },
                    {
                        props: { variant: 'pink' },
                        style: {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.accentPink,
                            '&:hover, &.Mui-focusVisible': {
                                borderColor: theme.palette.primary.light,
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.primary.light,
                            },
                        },
                    },
                ],
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '6px',
                        border: '1px solid',
                        borderColor: '#FFFFFF',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#FFFFFF',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#FFFFFF',
                        },
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        border: '1px solid',
                        borderColor: 'white',
                        color: 'white',
                    },
                },
            },
        } as ThemeOptions,
    };
}
