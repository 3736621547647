import { enableMapSet } from 'immer';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { createSwapSlice, SwapSlice } from './swapSlice';
import { createLayoutSlice, LayoutSlice } from './layoutSlice';
import { createWalletSlice, WalletSlice } from './walletSlice';
import { createProtocolSlice, ProtocolSlice } from './protocolSlice';
import { PoolSlice, createPoolSlice } from './poolSlice';
import { createTransactionsSlice, TransactionsSlice } from './transactionSlice';
import { createSingletonSubscriber } from './utils/createSingletonSubscriber';

enableMapSet();

export type RootStore = SwapSlice &
    LayoutSlice &
    WalletSlice &
    ProtocolSlice &
    PoolSlice &
    TransactionsSlice;

export const useRootStore = create<RootStore>()(
    subscribeWithSelector(
        devtools((...args) => {
            return {
                ...createSwapSlice(...args),
                ...createLayoutSlice(...args),
                ...createWalletSlice(...args),
                ...createProtocolSlice(...args),
                ...createPoolSlice(...args),
                ...createTransactionsSlice(...args),
            };
        }),
    ),
);

if (typeof document !== 'undefined') {
    document.onreadystatechange = function () {
        if (document.readyState === 'complete') {
            console.log('root loaded');
        }
    };
}

export const useUserPoolDataSubscription = createSingletonSubscriber(() => {
    return useRootStore.getState().refreshUserPoolData();
}, 60000);
