import { CallResult, getContract, IOP_20Contract, JSONRpcProvider, OP_20_ABI } from 'opnet';
import { PopulatedTransaction } from '../shared/types/transactions/PopuatedTransaction';
import { Address } from '@btc-vision/bsi-binary';
import { Token } from '../shared/types/Token';
import { ApproveType } from '../shared/types/transactions/Approval';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { getNetworkConfigKey } from '../utils/marketAndNetworkUtils';

export interface Allowance {
    readonly amount: bigint;
}

export class Op20Service {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    //Custom Methods
    public async GetTokenMetadata(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<Token> {
        try {
            const decimals = await this.getDecimals(currentNetworkConfig, contractAddress);
            const totalSupply = await this.getTotalSupply(currentNetworkConfig, contractAddress);
            const symbol = await this.getSymbol(currentNetworkConfig, contractAddress);
            const name = await this.getName(currentNetworkConfig, contractAddress);

            return {
                contractAddress,
                decimals,
                totalSupply,
                symbol,
                name,
            };
        } catch (error) {
            throw error;
        }
    }

    public approveTxData(
        currentNetwork: NetworkConfig,
        { user, token, spender, amount }: ApproveType,
    ): PopulatedTransaction {
        const contract = getContract<IOP_20Contract>(
            token,
            OP_20_ABI,
            this.getProvider(getNetworkConfigKey(currentNetwork)),
            user,
        );
        const txData = contract.encodeCalldata('approve', [spender, BigInt(amount)]);
        return {
            toAddress: token,
            callData: txData,
        };
    }

    public async approve(
        currentNetwork: NetworkConfig,
        { user, token, spender, amount }: ApproveType,
    ): Promise<PopulatedTransaction> {
        try {
            const contract = getContract<IOP_20Contract>(
                token,
                OP_20_ABI,
                this.getProvider(getNetworkConfigKey(currentNetwork)),
                user,
            );

            const contractResult = await contract.approve(spender, BigInt(amount));

            if (!contractResult) {
                //more logging
                throw Error('Error encoding approve');
            }

            const calldata = (contractResult as CallResult).calldata!;

            return {
                toAddress: token,
                callData: calldata,
            };
        } catch (error) {
            console.error('error in Op20Service:approve', error);
            throw error;
        }
    }

    public async allowance(
        currentNetwork: NetworkConfig,
        contractAddress: Address,
        spender: Address, //user
        owner: Address, //router
    ): Promise<bigint> {
        try {
            const contract = getContract<IOP_20Contract>(
                contractAddress,
                OP_20_ABI,
                this.getProvider(getNetworkConfigKey(currentNetwork)),
                owner,
            );
            const result = (await contract.allowance(spender, owner)) as CallResult;

            return result.decoded[0] as bigint;
        } catch (error) {
            console.error('error in Op20Service:allowance', error);
            throw error;
        }
    }

    public async balanceOf(
        currentNetwork: NetworkConfig,
        contractAddress: Address,
        address: Address,
    ): Promise<bigint> {
        try {
            const contract = getContract<IOP_20Contract>(
                contractAddress,
                OP_20_ABI,
                this.getProvider(getNetworkConfigKey(currentNetwork)),
                address,
            );
            const result = (await contract.balanceOf(address)) as CallResult;

            return result.decoded[0] as bigint;
        } catch (error) {
            console.error('error in Op20Service:balanceOf', error);
            throw error;
        }
    }

    public async getDecimals(
        currentNetwork: NetworkConfig,
        contractAddress: string,
    ): Promise<number> {
        const contract = getContract<IOP_20Contract>(
            contractAddress,
            OP_20_ABI,
            this.getProvider(getNetworkConfigKey(currentNetwork)),
        );
        const contractResult = await contract.decimals();

        return (contractResult as CallResult<{ decimals?: number }>).properties.decimals!;
    }

    public async getName(currentNetwork: NetworkConfig, contractAddress: string): Promise<string> {
        const contract = getContract<IOP_20Contract>(
            contractAddress,
            OP_20_ABI,
            this.getProvider(getNetworkConfigKey(currentNetwork)),
        );
        const contractResult = await contract.name();

        return (contractResult as CallResult<{ name?: string }>).properties.name!;
    }

    public async getSymbol(
        currentNetwork: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        const contract = getContract<IOP_20Contract>(
            contractAddress,
            OP_20_ABI,
            this.getProvider(getNetworkConfigKey(currentNetwork)),
        );
        const contractResult = await contract.symbol();

        return (contractResult as CallResult<{ symbol?: string }>).properties.symbol!;
    }

    public async getTotalSupply(
        currentNetwork: NetworkConfig,
        contractAddress: string,
    ): Promise<bigint> {
        const contract = getContract<IOP_20Contract>(
            contractAddress,
            OP_20_ABI,
            this.getProvider(getNetworkConfigKey(currentNetwork)),
        );
        const contractResult = await contract.totalSupply();

        return (contractResult as CallResult<{ totalSupply?: bigint }>).properties.totalSupply!;
    }
}
