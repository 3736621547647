import React from 'react';
import ContentContainer from '../../components/shared/ContentContainer';
import MainLayout from '../../layout/MainLayout';
import SwapContainer from '../../modules/swap/SwapContainer';
import { PageWithLayout } from '../../shared/types';

const Swap: PageWithLayout = () => {
    return (
        <>
            <ContentContainer>
                <SwapContainer />
            </ContentContainer>
        </>
    );
};

Swap.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

export default Swap;
