import { BrowserRouter as Router } from 'react-router-dom';
import { AppGlobalStyles } from './layout/AppGlobalStyles';
import React, { lazy, Suspense } from 'react';
import { SharedDependenciesProvider } from './providers/SharedDependenciesProvider';
import { Web3ContextProvider } from './providers/Web3ContextProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './shared/data/query/QueryClient';
import { SnackbarProvider } from './providers/SnackProvider';
import Routes from './routes';
import { BackgroundDataProvider } from './providers/BackgroundDataProvider';
import { ModalContextProvider } from './hooks/useModal';

const SwapModal = lazy(() => import('../src/components/transactions/Swap/SwapModal'));
const AddLiquidityModal = lazy(() => import('../src/components/transactions/AddLiquidity/AddLiquidityModal'));


const App = () => {
    return (
        <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
                <Web3ContextProvider>
                    <AppGlobalStyles>
                        <ModalContextProvider>
                            <SharedDependenciesProvider>
                                <BackgroundDataProvider>
                                    <Router>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Routes />
                                            <SwapModal />
                                            <AddLiquidityModal />
                                        </Suspense>
                                    </Router>
                                </BackgroundDataProvider>
                            </SharedDependenciesProvider>
                        </ModalContextProvider>
                    </AppGlobalStyles>
                </Web3ContextProvider>
            </QueryClientProvider>
        </SnackbarProvider>
    );
};

export default App;
