import * as networks from 'bitcoinjs-lib/src/networks';
import { NetworkConfig } from '../../shared/types/NetworkConfig';
import {
    FACTORY_ADDRESS_FRACTAL,
    FACTORY_ADDRESS_REGTEST,
    FACTORY_ADDRESS_TESTNET,
    MOTO_ADDRESS_FRACTAL,
    MOTO_ADDRESS_REGTEST,
    MOTO_ADDRESS_TESTNET,
    ROUTER_ADDRESS_FRACTAL,
    ROUTER_ADDRESS_REGTEST,
    ROUTER_ADDRESS_TESTNET,
    WBTC_ADDRESS_FRACTAL,
    WBTC_ADDRESS_REGTEST,
    WBTC_ADDRESS_TESTNET,
} from '@btc-vision/transaction';
import { NetworkKey } from './NetworkKey';

export type BaseNetworkConfig = Omit<NetworkConfig, 'explorerLinkBuilder'>;

export const networksConfig: {
    [key in NetworkKey]: BaseNetworkConfig;
} = {
    [NetworkKey.Regtest]: {
        name: 'Bitcoin Regtest',
        network: networks.regtest,
        privateJsonRPCUrl: 'https://regtest.opnet.org',
        publicJsonRPCUrl: ['https://regtest.opnet.org'],
        baseAssetSymbol: 'BTC',
        wrappedBaseAssetSymbol: 'WBTC',
        baseAssetDecimals: 8,
        explorerLink: 'https://opscan.org',
        isTestnet: true,
        isAvailable: true,
        networkLogoPath: '',
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            FACTORY: FACTORY_ADDRESS_REGTEST,
            ROUTER: ROUTER_ADDRESS_REGTEST,
            MOTO: MOTO_ADDRESS_REGTEST,
            WBTC: WBTC_ADDRESS_REGTEST,
        },
    },
    [NetworkKey.Testnet]: {
        name: 'Bitcoin Testnet',
        network: networks.testnet,
        privateJsonRPCUrl: 'https://testnet.opnet.org',
        publicJsonRPCUrl: ['https://testnet.opnet.org'],
        baseAssetSymbol: 'BTC',
        wrappedBaseAssetSymbol: 'WBTC',
        baseAssetDecimals: 8,
        explorerLink: 'https://testnet.opscan.org',
        isTestnet: true,
        isAvailable: true,
        networkLogoPath: '',
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            FACTORY: FACTORY_ADDRESS_TESTNET,
            ROUTER: ROUTER_ADDRESS_TESTNET,
            MOTO: MOTO_ADDRESS_TESTNET,
            WBTC: WBTC_ADDRESS_TESTNET,
        },
    },
    [NetworkKey.Bitcoin]: {
        name: 'Bitcoin',
        network: networks.testnet,
        privateJsonRPCUrl: 'https://testnet.opnet.org',
        publicJsonRPCUrl: ['https://testnet.opnet.org'],
        baseAssetSymbol: 'BTC',
        wrappedBaseAssetSymbol: 'WBTC',
        baseAssetDecimals: 8,
        explorerLink: 'https://testnet.opscan.org',
        isTestnet: false,
        isAvailable: false,
        networkLogoPath: '',
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            FACTORY: FACTORY_ADDRESS_TESTNET,
            ROUTER: ROUTER_ADDRESS_TESTNET,
            MOTO: MOTO_ADDRESS_TESTNET,
            WBTC: WBTC_ADDRESS_TESTNET,
        },
    },
    [NetworkKey.FractalTestnet]: {
        name: 'Fractal Testnet',
        network: networks.bitcoin,
        privateJsonRPCUrl: 'https://fractal.opnet.org',
        publicJsonRPCUrl: ['https://fractal.opnet.org'],
        baseAssetSymbol: 'BTC',
        wrappedBaseAssetSymbol: 'WBTC',
        baseAssetDecimals: 8,
        explorerLink: 'https://fractal.opscan.org',
        isTestnet: true,
        isAvailable: true,
        networkLogoPath: '',
        enabledFeatures: {
            swap: true,
            staking: false,
            faucet: false,
            wrap: false,
        },
        addresses: {
            FACTORY: FACTORY_ADDRESS_FRACTAL,
            ROUTER: ROUTER_ADDRESS_FRACTAL,
            MOTO: MOTO_ADDRESS_FRACTAL,
            WBTC: WBTC_ADDRESS_FRACTAL,
        },
    },
};
