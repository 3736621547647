import BigNumber from 'bignumber.js';

const bn10 = new BigNumber(10);
const bn10PowLookup: { [key: number]: BigNumber } = {};

const pow10 = (n: number): BigNumber => {
    if (!bn10PowLookup[n]) {
        bn10PowLookup[n] = bn10.pow(n);
    }
    return bn10PowLookup[n];
};

export declare type BigNumberValue = string | number | BigNumber;

export const MAX_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const isWbtc = () => {
    return true;
};

export const toBigInt = (value: string | number, decimals: number): bigint => {
    const bnValue = new BigNumber(value);
    const scaledValue = bnValue.multipliedBy(new BigNumber(10).pow(decimals));
    return BigInt(scaledValue.toFixed(0));
};

export const fromBigInt = (value: bigint, decimals: number): number => {
    const bnValue = new BigNumber(value.toString());
    const scaledValue = bnValue.dividedBy(pow10(decimals));
    return scaledValue.toNumber();
};

export const valueToBigNumber = (value: bigint | string | number): BigNumber =>
    new BigNumber(value.toString());

export const normalizeBN = (amount: bigint | string | number, decimals: number): BigNumber => {
    return valueToBigNumber(amount).dividedBy(pow10(decimals));
};

export const denormalizeBN = (amount: BigNumber, decimals: number): BigNumber => {
    return amount.multipliedBy(pow10(decimals));
};

export const bigNumberToBigInt = (bigNumber: BigNumber, precision: number): bigint => {
    const factor = pow10(precision);
    const scaledBigNumber = bigNumber.multipliedBy(factor);
    const bigNumberStr = scaledBigNumber.toFixed(0);
    return BigInt(bigNumberStr);
};

export const btcFromSatoshis = (satoshis: number | string | bigint): string => {
    return (Number(satoshis || 0n) / 100000000)
        .toFixed(7)
        .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
};

export const satoshisFromBtc = (amount: number | string | bigint): bigint => {
    return BigInt(Math.floor(Number(amount) * 100000000));
};
