import { useRootStore } from '../store/root';
import { useWeb3Context } from './useWeb3Context';

export function useIsWrongNetwork() {
    const currentNetwork = useRootStore((store) => store.currentNetwork);
    const { currentNetwork: web3Network } = useWeb3Context();

    const isWrongNetwork = currentNetwork != web3Network;

    return {
        isWrongNetwork,
    };
}
