// src/components/SwapInputBox.tsx
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import TokenSelectModal from '../modals/TokenSelectModal';
import { GenericTokenIcon } from '../images/GenericTokenIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Token } from '../../shared/types/Token';
import { UseQueryResult } from '@tanstack/react-query';
import { useTokenMetdata } from '../../hooks/useTokenMetadata';

export interface TokenSelectInputProps {
    title?: string;
    tokenContractAddress?: string;
    disabled?: boolean;
    setUserSelectedToken: (token: Token) => void;
    onTokenChange?: (token: Token) => void;
}

const TokenSelectInput: React.FC<TokenSelectInputProps> = ({
    tokenContractAddress,
    setUserSelectedToken,
}) => {
    const [selectedContractAddress, setSelectedContractAddress] = useState(tokenContractAddress);
    const [selectedToken, setSelectedToken] = useState<Token | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const tokenData: UseQueryResult<Token, Error> = useTokenMetdata(selectedContractAddress || '');

    useEffect(() => {
        if (tokenContractAddress && tokenContractAddress !== selectedContractAddress) {
            setSelectedContractAddress(tokenContractAddress);
        }
    }, [tokenContractAddress]);

    useEffect(() => {
        if (
            selectedContractAddress &&
            tokenData.data &&
            selectedToken?.contractAddress !== selectedContractAddress
        ) {
            setSelectedToken(tokenData.data);
            setUserSelectedToken(tokenData.data);
        }
    }, [selectedContractAddress, tokenData.data, selectedToken, setUserSelectedToken]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectToken = async (contractAddress: string) => {
        setSelectedContractAddress(contractAddress);
        handleCloseModal();
    };

    return (
        <>
            <Box
                onClick={handleOpenModal}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderRadius: 4,
                    cursor: 'pointer',
                    mb: 2,
                    width: '100%',
                    height: '60px',
                    border: '1px solid #FFFFFF',
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {selectedToken ? (
                        <>
                            {selectedToken.icon ? (
                                <img
                                    src={selectedToken.icon}
                                    alt={selectedToken.symbol}
                                    style={{ width: 24, height: 24, marginRight: 8 }}
                                />
                            ) : (
                                <GenericTokenIcon sx={{ marginRight: 1 }} />
                            )}
                            <Typography variant="main21">{selectedToken.symbol}</Typography>
                        </>
                    ) : (
                        <Typography
                            variant="main16"
                            sx={{ lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                            Select Token
                        </Typography>
                    )}
                </Box>
                <ArrowDropDownIcon />
            </Box>
            <TokenSelectModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onSelectToken={handleSelectToken}
            />
        </>
    );
};

export default TokenSelectInput;
