import React from 'react';
import { PageWithLayout } from '../../shared/types';
import ContentContainer from '../../components/shared/ContentContainer';
import MainLayout from '../../layout/MainLayout';
import CurrentPoolsPanel from '../../modules/pool/CurrentPoolsPanel';
import { Container } from '@mui/material';

const Pool: PageWithLayout = () => {
    return (
        <>
            <ContentContainer>
                <Container
                    maxWidth="sm"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 4,
                        mb: 4,
                        width: '100%',
                        height: '100%',
                        padding: '0 16px',
                        boxSizing: 'border-box',
                    }}>
                    <CurrentPoolsPanel />
                </Container>
            </ContentContainer>
        </>
    );
};

Pool.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

export default Pool;
