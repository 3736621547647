import { StateCreator } from 'zustand';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { RootStore } from './root';
import { JSONRpcProvider } from 'opnet';
import { getNetworkConfig, getProvider } from '../utils/marketAndNetworkUtils';
import { NetworkKey } from '../configs/ui-config/NetworkKey';

const getInitialNetwork = (): NetworkKey => {
    const savedNetwork = localStorage.getItem('currentNetwork');
    return (savedNetwork as NetworkKey) || NetworkKey.Regtest;
};

export interface ProtocolSlice {
    currentNetwork: NetworkKey;
    setCurrentNetwork: (network: NetworkKey) => void;
    currentNetworkConfig: NetworkConfig;
    jsonRpcProvider: (network?: NetworkKey) => JSONRpcProvider;
}

export const createProtocolSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    ProtocolSlice
> = (set, get) => {
    const initialNetwork = getInitialNetwork();
    return {
        currentNetwork: initialNetwork,
        currentNetworkConfig: getNetworkConfig(initialNetwork),
        jsonRpcProvider: (network) => getProvider(network ?? get().currentNetwork),
        setCurrentNetwork: (network) => {
            localStorage.setItem('currentNetwork', network);
            set({
                currentNetwork: network,
                currentNetworkConfig: getNetworkConfig(network),
            });
        },
    };
};
