// src/components/SwapInputBox.tsx
import React, { useEffect, useState } from 'react';
import { Box, Button, InputBase, Typography } from '@mui/material';
import TokenSelectModal from '../modals/TokenSelectModal';
import { GenericTokenIcon } from '../images/GenericTokenIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Token } from '../../shared/types/Token';
import { useTokenBalance } from '../../hooks/useTokenBalance';
import { fromBigInt } from '../../utils/tokenUtils';
import { UseQueryResult } from '@tanstack/react-query';
import { useTokenMetdata } from '../../hooks/useTokenMetadata';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
}

export const NumberFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    if (values.value !== props.value)
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value || '',
                            },
                        });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
            />
        );
    },
);

export interface AssetInputProps {
    title?: string;
    tokenContractAddress?: string;
    tokenAmount?: string;
    priceImpact?: number | null;
    disabled?: boolean;
    setTokenAmount?: (amount: string) => void;
    setUserSelectedToken?: (token: Token) => void;
    onTokenChange?: (token: Token) => void;
}

const AssetInput: React.FC<AssetInputProps> = ({
    title,
    tokenContractAddress,
    tokenAmount,
    priceImpact,
    disabled = false,
    setTokenAmount,
    setUserSelectedToken,
}) => {
    const [selectedContractAddress, setSelectedContractAddress] = useState(tokenContractAddress);
    const [selectedToken, setSelectedToken] = useState<Token | null>(null);
    const [balance, setBalance] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const balanceBigInt: UseQueryResult<bigint, Error> = useTokenBalance(
        selectedContractAddress || '',
    );
    const tokenData: UseQueryResult<Token, Error> = useTokenMetdata(selectedContractAddress || '');

    useEffect(() => {
        if (tokenContractAddress && tokenContractAddress !== selectedContractAddress) {
            setSelectedContractAddress(tokenContractAddress);
        }
    }, [tokenContractAddress]);

    useEffect(() => {
        if (tokenAmount !== undefined && setTokenAmount) {
            setTokenAmount(tokenAmount);
        }
    }, [tokenAmount, setTokenAmount]);

    useEffect(() => {
        if (
            selectedContractAddress &&
            tokenData.data &&
            selectedToken?.contractAddress !== selectedContractAddress
        ) {
            setSelectedToken(tokenData.data);
            if (setUserSelectedToken) setUserSelectedToken(tokenData.data);
        }
    }, [selectedContractAddress, tokenData.data, selectedToken, setUserSelectedToken]);

    useEffect(() => {
        if (balanceBigInt.data !== undefined && selectedToken && selectedToken.decimals) {
            const normalizedBalance = fromBigInt(balanceBigInt.data, selectedToken.decimals);
            setBalance(normalizedBalance);
        }
    }, [balanceBigInt.data, selectedToken]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSelectToken = async (contractAddress: string) => {
        setSelectedContractAddress(contractAddress);
        handleCloseModal();
    };

    const handleAmountChange = (value: string) => {
        if (setTokenAmount) setTokenAmount(value);
    };

    const handleMaxClick = () => {
        if (setTokenAmount) setTokenAmount(balance.toString());
    };

    return (
        <Box sx={{ p: 2, backgroundColor: '#1E1243', borderRadius: '8px', position: 'relative' }}>
            <Box sx={{ pb: 2 }}>
                <Typography
                    variant="caption"
                    color="gray"
                    align="left"
                    gutterBottom>
                    {title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <InputBase
                        fullWidth
                        placeholder="0.0"
                        sx={{
                            height: '100px',
                            fontSize: '25px',
                        }}
                        disabled={disabled}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        value={tokenAmount}
                        // eslint-disable-next-line
                        inputComponent={NumberFormatCustom as any}
                    />
                    <Button
                        variant="contained"
                        onClick={handleOpenModal}
                        sx={{
                            backgroundColor: '#6a1b9a',
                            display: 'inline-flex',
                            textTransform: 'none',
                            padding: '6px 40px',
                            width: 'auto',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                        }}>
                        {selectedToken ? (
                            <>
                                {selectedToken.icon ? (
                                    <img
                                        src={selectedToken.icon}
                                        alt={selectedToken.symbol}
                                        style={{ width: 24, height: 24, marginRight: 8 }}
                                    />
                                ) : (
                                    <GenericTokenIcon sx={{ marginRight: 1 }} />
                                )}
                                <Typography>{selectedToken.symbol}</Typography>
                            </>
                        ) : (
                            <Typography
                                variant="main16"
                                sx={{ lineHeight: 1, display: 'flex', alignItems: 'center' }}>
                                Select Token
                            </Typography>
                        )}
                        <ArrowDropDownIcon />
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 8,
                    right: 1,
                }}>
                <Typography
                    variant="caption"
                    color="gray">
                    Balance: {balance.toFixed(3)}
                </Typography>
                <Button
                    disabled={balance <= 0}
                    variant="text"
                    onClick={handleMaxClick}
                    sx={{
                        color: balance > 0 ? '#ff4081' : 'gray',
                        textTransform: 'none',
                        ml: 1,
                    }}>
                    Max
                </Button>
            </Box>
            {priceImpact && (
                <Box>
                    <Typography
                        variant="caption"
                        color="gray"
                        sx={{ position: 'absolute', bottom: 8, left: 20 }}>
                        Impact: {priceImpact.toFixed(2)}%
                    </Typography>
                </Box>
            )}

            <TokenSelectModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onSelectToken={handleSelectToken}
            />
        </Box>
    );
};

export default AssetInput;
